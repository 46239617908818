<template>
  <div class="row mx-2 my-0">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        p-5
      "
    >
      <h1 class="mb-5">Online Booking</h1>
      <alert
        v-if="onlineBookingSettingsLoading"
        class="light-shadow mx-4 mb-4"
      />
      <form
        v-else
        @submit.prevent="updateOnlineBookingSettingsHelper"
        validate
        class="d-flex flex-column"
      >
        <div class="form-group d-flex flex-md-row flex-column">
          <label class="col-md-4 px-0"> Is online booking enabled? </label>
          <select required v-model="dataToSend.is_enabled" class="form-control">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
        <div class="form-group d-flex flex-md-row flex-column">
          <label class="col-md-4 px-0">
            Allow providers to accept and reject requests?
          </label>
          <select
            required
            v-model="dataToSend.providers_can_perform_actions"
            class="form-control"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
        <save
          classes="mt-3 save-button btn btn-theme align-self-end"
          :saving="updateOnlineBookingSettingsLoading"
        >
          Save Changes
        </save>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      dataToSend: {
        is_enabled: true,
        providers_can_perform_actions: true,
      },
    };
  },
  async mounted() {
    const result = await this.getOnlineBookingSettings();
    if (result) {
      this.dataToSend = result;
    }
  },
  computed: {
    ...mapState({
      onlineBookingSettingsLoading: (state) =>
        state.settings.onlineBookingSettings.loading,
      onlineBookingSettings: (state) =>
        state.settings.onlineBookingSettings.data,
      updateOnlineBookingSettingsLoading: (state) =>
        state.settings.updateOnlineBookingSettingsLoading,
    }),
  },
  methods: {
    ...mapActions({
      getOnlineBookingSettings: "settings/getOnlineBookingSettings",
      updateOnlineBookingSettings: "settings/updateOnlineBookingSettings",
    }),
    updateOnlineBookingSettingsHelper() {
      this.updateOnlineBookingSettings(this.dataToSend);
    },
  },
};
</script>
